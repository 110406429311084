<template>
    <div 
        :class="[
            'close-button-container',
            'position-relative d-flex justify-content-center align-items-center',
            'user-select-none toggle-active cursor-pointer',
            'rounded-circle',
            light && 'light-button text-muted'
        ]"
        @click="close"
    >
        <span>
            <Icon icon-name="cancel"/>
        </span>
    </div>
</template>

<script>
import Icon from '@/components/shared/Icon.vue';

export default {
    name: 'CloseButton',
    components: {
        Icon
    },
    emits: ['close'],
    props: {
        light: Boolean
    },
    methods: {
        close: function () {
            this.$emit('close');
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

.close-button-container {
    height: 21px;
    width: 21px;
    font-size: 13px;
    line-height: 0;
    opacity: .6;
    @extend .bg-brand-black;
    @extend .cursor-pointer;
    @extend .text-brand-white;

    &.light-button {
        height: 38px;
        width: 38px;
        font-size: 16px;
        opacity: 1;
        background-color: rgba($color: #000000, $alpha: .15);
    }

    &:hover {
        opacity: .85;
        @extend .bg-brand-primary;
    }
}
    
@media only screen and (max-width: 576px) {
    .close-button-container {
        &.light-button {
            height: 32px;
            width: 32px;
            font-size: 13px;
        }
    }
}
</style>
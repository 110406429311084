<template>
    <div class="option-container mx-0 my-3 m-sm-3">
        <router-link
            v-if="link"
            :to="link"
            class="d-flex h-100 justify-content-center align-items-center router-link"
        >
            <div class="w-100 h-100 py-4 px-3 d-flex flex-column justify-content-between align-items-center">
                <slot/>
            </div>
        </router-link>

        <div v-else class="w-100">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "Card",
    props: {
        link: String
    }
}
</script>

<style lang="scss">
.router-link {
    text-decoration: none;

    &:visited, &:link {
        color: #000;
    }

    &:hover * {
        color: #FFFFFF !important;
    }
}

.option-container {
    background-color: rgba($color: #ebebeb, $alpha: .8);
    border-radius: 8px;
    box-shadow: 6px 5px 5px 0px rgba($color: #000000, $alpha: .1);
    display: inline-block;
    height: 308px;
    width: 300px;

    &:hover {
        background-color: var(--client-color);
        box-shadow: 8px 10px 10px 0px rgba($color: #000000, $alpha: .1);
    }
}

a, .option-container {
    transition: .3s;
}

@media only screen and (max-width: 500px) {
  .option-container {
    width: 255px;
  }
}
</style>

<template>
    <div class="position-relative">
        <div id="search-icon" class="d-flex justify-content-center align-items-center">
            <img src="https://vvs.autosyncstudio.com/static/icons/search-icon.png">
        </div>

        <div v-if="query" class="close-button">
            <CloseButton @close="clearInput"/>
        </div>

        <div
            v-if="!query"
            :class="[
                'd-md-none',
                'type-search-button type-search-button-position type-search-icon',
                'bg-brand-light-gray',
                'cursor-pointer toggle-active',
                'border rounded'
            ]"
            style="filter: opacity(.6083)"
            @click.stop="ToggleTypeSearch()"
        ></div>
        
        <div
            id="search-list-container"
            v-if="!query"
            class="type-search-list-container shadow-sm bg-brand-primary text-brand-body fw-regular"
            @click.stop="showSearchTypeList"
        >
            <div class="d-flex justify-content-center align-items-center w-100 h-100 px-2 px-sm-4 cursor-pointer toggle-active user-select-none position-relative">
                <small class="d-none d-sm-block">{{searchVehicles ? 'VEHICLE':'PRODUCT'}}</small>
                <small class="d-block d-sm-none">
                    <small style="font-size: 90%; letter-spacing: 1px">
                        {{searchVehicles ? 'VEHICLE':'PRODUCT'}}
                    </small>
                </small>
                <span class="ms-1">
                    <Icon :icon-name="searchTypeList ? 'navArrowUp':'navArrowDown'"/>
                </span>

                <div
                    v-show="searchTypeList"
                    class="type-search-list w-100 bg-brand-body text-brand-text shadow-sm"
                >
                    <div
                        class="px-2 px-sm-4 py-2 type-search-list-item"
                        @click.stop="searchVehicles = true; searchWheels = false; searchTypeList = false"
                    >
                        <small>VEHICLE</small>
                    </div>
                    <div
                        class="px-2 px-sm-4 py-2 type-search-list-item"
                        @click.stop="searchWheels = true; searchVehicles = false; searchTypeList = false"
                    >
                        <small>PRODUCT</small>
                    </div>
                    
                </div>
            </div>
        </div>

        <label for="query" class="w-100">
            <input
                type="text"
                class="py-2"
                id="query"
                name="query"
                v-model="query"
                autocomplete="off"
                placeholder=" "
            >
            <span class="fw-regular search-tool-label">
                <small v-if="searchVehicles">Search for vehicle model...</small>
                <small v-if="searchWheels">Search for product...</small>
            </span>
        </label>
        <div v-if="showResults && searchVehicles" id="search-result-container" class="w-100 my-2">
            <div id="search-result" class="d-flex flex-column justify-content-center align-items-center w-100 bg-brand-body p-4">
                <!-- While is getting the response -->
                <DotsFlashingLoader v-if="searching"/>
                <transition name="fade">
                    <div v-show="query.length < 3"><small>At least 3 characters requireds to search...</small></div>
                </transition>

                <!-- When isn't searching -->
                <div v-if="query.length >= 3 && !searching && !models"><small>0 results. Try something different...</small></div>
                <div v-if="!searching && models" class="w-100 d-flex flex-column justify-content-center align-items-center">
                    <div class="container-fluid m-0 p-0">
                        <div v-if="makes" class="mx-3 my-2 mx-md-5 my-md-2 pb-2 border-bottom text-start fw-medium">MAKES</div>
                        <div
                            v-for="(make, index) in makes"
                            :key="index"
                            class="row align-items-center m-0 p-0 px-2 px-md-4 my-2 cursor-pointer search-item"
                            @click="getMakeUrl(make)"
                        >
                            <div class="col-5 col-sm-4 py-2">
                                <img
                                    :src="getImgUrlMakes(make.Logo)"
                                    alt=""
                                    onerror="this.className='d-none'"
                                    :class="['d-none', getImgUrlMakes(make.Logo) && 'make-image']"
                                    onload="this.classList.remove('d-none')"
                                >
                                <div class="d-none preloader bg-brand-light-gray">
                                    <DotsFlashingLoader/>
                                </div>
                            </div>
                            <div class="col-7 col-sm-8 text-start py-2 px-2 px-md-4">
                                <small class="fw-regular">{{make.Make}}</small>
                            </div>
                        </div>
                        <div v-if="models" class="mx-3 my-2 mx-md-5 my-md-2 pb-2 border-bottom text-start fw-medium">MODELS</div>
                        <div
                            v-for="(model, index) in models"
                            :key="index"
                            class="row align-items-center m-0 p-0 px-2 px-md-4 my-2 cursor-pointer search-item"
                            @click="getVehicleUrl(model)"
                        >
                            <div class="col-5 col-sm-4">
                                <img
                                    :src="getImgUrlModels(model.Img001['240'])"
                                    alt=""
                                    onerror="this.className='d-none'"
                                    :class="['d-none', getImgUrlModels(model.Img001['240']) && 'model-image']"
                                    onload="this.classList.remove('d-none')"
                                >
                                <div class="d-none preloader bg-brand-light-gray">
                                    <DotsFlashingLoader/>
                                </div>
                            </div>
                            <div class="col-7 col-sm-8 text-start py-2 px-2 px-md-4">
                                <small class="fw-regular">{{model.Make}} {{model.Model}} {{model.Body.toLowerCase() != 'sport utility' ? model.Body:'' }}</small>
                            </div>
                        </div>
                        <div v-if="moreResults" class="w-100 text-center link cursor-pointer toggle-active user-select-none">
                            <small class="text-brand-link" @click="searchResults(10)"><u>Show more results</u></small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showResults && searchWheels" id="search-result-container" class="w-100 my-2">
            <div id="search-result" class="d-flex flex-column justify-content-center align-items-center w-100 bg-brand-body p-4">
                <!-- While is getting the response -->
                <DotsFlashingLoader v-if="searching"/>
                <transition name="fade">
                    <div v-show="query.length < 3"><small>At least 3 characters requireds to search...</small></div>
                </transition>

                <!-- When isn't searching -->
                <div v-if="query.length >= 3 && !searching && !styles"><small>0 results. Try something different...</small></div>
                <div v-if="!searching && styles" class="w-100 d-flex flex-column justify-content-center align-items-center">
                    <div class="container-fluid m-0 p-0">
                        <div v-if="brands" class="mx-3 my-2 mx-md-5 my-md-2 pb-2 border-bottom text-start fw-medium">BRANDS</div>
                        <div
                            v-for="(brand, index) in brands"
                            :key="index"
                            class="row align-items-center m-0 p-0 px-2 px-md-4 my-2 cursor-pointer search-item"
                            @click="getBrandUrl(brand)"
                        >
                            <div class="col-5 col-sm-4 p-3">
                                <img
                                    :src="getImgUrlBrands(getBrandLogo(brand))"
                                    alt=""
                                    onerror="this.className='d-none'"
                                    :class="['d-none', getImgUrlBrands(getBrandLogo(brand)) && 'brand-image']"
                                    onload="this.classList.remove('d-none')"
                                >
                                <div class="d-none preloader bg-brand-light-gray">
                                    <DotsFlashingLoader/>
                                </div>
                            </div>
                            <div class="col-7 col-sm-8 text-start py-2 px-2 px-md-4">
                                <small class="fw-regular">{{brand}}</small>
                            </div>
                        </div>
                        <div v-if="styles" class="mx-3 my-2 mx-md-5 my-md-2 pb-2 border-bottom text-start fw-medium">STYLES</div>
                        <div
                            v-for="(style, index) in styles"
                            :key="index"
                            class="row align-items-center m-0 p-0 px-2 px-md-4 my-2 cursor-pointer search-item"
                            @click="getStyleUrl(style)"
                        >
                            <div class="col-4 col-md-3">
                                <img
                                    :src="getImgUrlStyles(style.Img0001)"
                                    alt=""
                                    onerror="this.className='d-none'"
                                    :class="['d-none', getImgUrlStyles(style.Img0001) && 'wheel-style-image p-1']"
                                    onload="this.classList.remove('d-none')"
                                >
                                <div class="d-none preloader bg-brand-light-gray">
                                    <DotsFlashingLoader/>
                                </div>
                            </div>
                            <div class="col-8 col-md-9 text-start py-2 px-2 px-md-4">
                                <small class="fw-regular">{{style.Brand}} {{style.Model}} {{style.Submodel || '' }}</small>
                                <br>
                                <small v-for="(diameter, key) in style.Diameters" :key="key"> {{key > 0 ? '|':''}} {{diameter}}" </small>
                            </div>
                        </div>
                        <div v-if="moreResults" class="w-100 text-center link cursor-pointer toggle-active user-select-none">
                            <small class="text-brand-link" @click="searchResults(10)"><u>Show more results</u></small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DotsFlashingLoader from '@/components/shared/DotsFlashingLoader';
import CloseButton from "@/components/shared/CloseButton.vue";
import apiRequest from "@/utils/apiRequest";
import Icon from '@/components/shared/Icon.vue';

export default {
    name: "SearchTool",
    components: {
        DotsFlashingLoader,
        CloseButton,
        Icon
    },
    data: function () {
        return {
            query: null,
            showResults: false,
            searching: false,
            searchVehicles: true,
            searchWheels: false,
            searchTypeList: false,
            imgUrlBaseVehicles: null,
            imgUrlBaseWheels: null,
            moreResults: false,
            makes: null,
            models: null,
            brands: null,
            styles: null,
            resultSize: 10,
            timeLastType: null
        }
    },
    computed: {
        imgUrlBaseMakes: function () {
            return this.$store.getters.vehiclesMakes.baseUrl;
        },
        imgUrlBaseBrands: function () {
            return this.$store.getters.wheelsBrands.baseUrl;
        }
    },
    methods: {
        ToggleTypeSearch: function () {
            if (this.searchVehicles) {
                this.searchVehicles = false;
                this.searchWheels = true;
            }else if (this.searchWheels) {
                this.searchWheels = false;
                this.searchVehicles = true;
            }
        },
        getImgUrlBase: function (response) {
            if (response) {
                if (response.ImgUrlBase) {
                    return response.ImgUrlBase || null;
                }
            }
            return null;
        },
        getImgUrlModels: function (url) {
            if (this.imgUrlBaseVehicles && url) {
                return `${this.imgUrlBaseVehicles}${url}`;
            }
            return '';
        },
        getImgUrlMakes: function (url) {
            if (this.imgUrlBaseMakes && url) {
                return `${this.imgUrlBaseMakes}${url}`;
            }
            return '';
        },
        getImgUrlBrands: function (url) {
            if (this.imgUrlBaseBrands && url) {
                return `${this.imgUrlBaseBrands}${url}`;
            }
            return '';
        },
        getImgUrlStyles: function (url) {
            if (this.imgUrlBaseWheels && url) {
                return `${this.imgUrlBaseWheels}${url}`;
            }
            return '';
        },
        getBrands: function () {
            let brands = [];
            this.$store.getters.wheelsBrands.brands.forEach(brand => {
                if (brand.Brand.toUpperCase().trim().includes(this.query.toUpperCase().trim())) {
                    if (!brands.includes(brand.Brand)) {
                        brands.push(brand.Brand);
                    }
                }
            });
            return brands;
        },
        getModels: function (response) {
            if (response) {
                if (response.Models) {
                    return response.Models.length > 0 ? response.Models:null;
                }
            }
            return null;
        },
        getStyles: function (response) {
            if (response) {
                if (response.WheelStyles) {
                    return response.WheelStyles.length > 0 ? response.WheelStyles:null;
                }
            }
            return null;
        },
        getVehicleUrl: function (model) {
            if (model) {
                this.$router.push(`/builder?vehicle_make=${model.Make}&vehicle_model=${model.Model}&vehicle_year=${model.MaxYear}&vehicle_body=${model.Body}`);
            } else {
                this.$router.push('/404');
            }
        },
        getMakeUrl: function (make) {
            if (make) {
                this.$router.push(`/vehicles/make/${make.Make}`);
            } else {
                this.$router.push('/404');
            }
        },
        getBrandUrl: function (brand) {
            if (brand) {
                this.$router.push(`/builder?wheels_view&wheel_brand=${brand}`);
            } else {
                this.$router.push('/404');
            }
        },
        getStyleUrl: function (style) {
            if (style) {
                this.$router.push(`/builder?wheels_view&wheel_brand=${style.Brand}&wheel_style_id=${style.Id}`);
            } else {
                this.$router.push('/404');
            }
        },
        getBrandLogo: function (val) {
            const brands = this.$store.getters.wheelsBrands.brands;
            const result = brands.filter(brand => brand.Brand.toUpperCase() === val.toUpperCase());
            return result ? result[0].Logo:'';
        },
        clearInput: function () {
            this.query = null;
            this.models = null;
            this.makes = null;
            this.searching = false;
            this.showResults = false;
            this.resultSize = 10;
        },
        searchResults: async function (number = 0, query = null) {
            const inputQuery = encodeURIComponent(query || this.query).replace('&', '%26');
            this.resultSize += number;

            if (this.searchVehicles) {
                const vehicleThumbnails = this.$store.getters.clientSettings.vehicleThumbnails;
                const colorFilter = vehicleThumbnails == 'mono' ? `&f-imgColor=${vehicleThumbnails}`:'';
                const request = await apiRequest(`/vehicles/models?i-maxYear&i-body&i-img001=240${colorFilter}&f-query=${inputQuery}&p-size=${this.resultSize}`);
                this.imgUrlBaseVehicles = this.getImgUrlBase(request);
                this.models = this.getModels(request);
                this.moreResults = request.MoreItems || false;
                this.makes = this.$store.getters.vehiclesMakes.makes.filter( make => make.Make.toLowerCase().includes(inputQuery.toLowerCase()));
                this.makes = this.makes.length > 0 ? this.makes:null;
            } else if (this.searchWheels) {
                const request = await apiRequest(`/wheels/styles?i-tags&i-diameters&i-img0001&f-query=${inputQuery}&p-size=${this.resultSize}`);
                this.imgUrlBaseWheels = this.getImgUrlBase(request);
                this.styles = this.getStyles(request);
                this.brands = this.getBrands();
                this.moreResults = request.MoreItems || false;
            }
        },
        showSearchTypeList: function () {
            this.searchTypeList = !this.searchTypeList;
            const element = document.getElementById('search-list-container');
            const fnElementContains = ((e) => {
                if (!element.contains(e.target)){
                    this.searchTypeList = false;
                    window.removeEventListener('click', fnElementContains);
                }
            });

            if (this.searchTypeList) {
                window.addEventListener('click', fnElementContains);
            }
        }
    },
    watch: {
        query: async function (newVal) {
            if (newVal) {
                this.showResults = true;

                if (newVal.length >= 3) {
                    this.searching = true;

                    if (!this.timeLastType) {
                        this.timeLastType = new Date().getTime();
                        await this.searchResults(0, newVal);
                    } else {
                        const newTimeLastType = new Date().getTime();
                        const waitTime = 1000 - (newTimeLastType - this.timeLastType);

                        setTimeout(async () => {
                            await this.searchResults(0, newVal);
                        }, waitTime > 1000 ? 0:waitTime);

                        this.timeLastType = newTimeLastType;
                    }
                } else {
                    this.models = null;
                }
            }
            this.searching = false;
        }
    },
    created: function () {
        window.addEventListener('click', (e) => {
            if (!this.$el.contains(e.target)){
                this.models = null;
                this.searching = false;
                this.showResults = false;
                this.resultSize = 10;
            }
        })
    },
    mounted: function () {
        const inputField = document.getElementById('query');
        inputField.focus();
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

#search-icon {
    position: absolute;
    height: 24px;
    top: calc((100% - 24px) / 2);
    left: .6083rem;
    z-index: 1;
}

#search-result {
    color: #707070;
    
    &#{-container} {
        position: absolute;
        top: 100%;
        border-radius: .61rem;
        box-shadow: 0 .1rem .6rem rgb(0 0 0 / 16%);
        font-size: 95%;
        max-height: 56vh;
        overflow-y: auto;
        transition: height 0.3s ease;
        -o-transition: height 0.3s ease;
        -moz-transition: height 0.3s ease;
        -webkit-transition: height 0.3s ease;
    }

    .search-item {
        border-radius: .61rem;
        @extend .bg-brand-body-secondary;

        &:hover {
            @extend .bg-brand-light-gray;
        }
    }
}


.type-search-button {
    $icon-size: 28px;

    min-height: $icon-size;
    min-width: $icon-size * 1.6803;
    padding: 0.20rem;

    &#{-position} {
        position: absolute;
        top: calc((100% - #{$icon-size}) / 2);
        right: 1.5em;
        z-index: 1;
    }

    &.rounded,
    &#{-position}.rounded{
        border-radius: $icon-size/2.5 !important;
    }
}

.type-search-icon {
    $icon-size: 28px;

    &.selected {
        background-color: var(--client-color);
    }

    &.rounded-right{
        border-radius: 0 $icon-size/2.5 $icon-size/2.5 0 !important;
    }

    &.rounded-left{
        border-radius: $icon-size/2.5 0 0 $icon-size/2.5 !important;
    }
}

.type-search-list-container {
    $list-size: 42px;
    height: $list-size;
    position: absolute;
    top: calc((100% - #{$list-size}) / 2);
    right: 0;
    z-index: 1;
    border-radius: 0 $list-size $list-size 0;
    font-size: 90%;
    letter-spacing: 0.8px;

    .type-search-list {
        position: absolute;
        top: calc(100% + .25rem);
        right: 0;

        .type-search-list-item {
            &:hover {
                @extend .bg-brand-light-gray;
            }
        }
    }
}

.close-button {
    position: absolute;
    top: calc((100% - 21px) / 2);
    right: 1.5em;
    z-index: 1;
}

img {
    &.brand-image {
        max-height: 75px;
        max-width: 75px;
    }

    &.wheel-style-image {
        max-height: 60px;
        max-width: 60px;
    }

    &.model-image {
        max-width: 120px;
    }

    &.make-image {
        max-height: 45px;
        max-width: 45px;
    }
}

label {
    position: relative;
    
    input {
        border: 1px solid #8a8a8a;
        border-radius: 44px;
        height: 44px;
        font-size: 90%;
        color: #2F2F2F;
        width: 100%;
        padding-left: 3.6em;
        padding-right: 3.6em;

        &::placeholder {
            font-style: italic;
            color: #a2a2a2;
        }
    }

    span {
        font-size: 1.1rem;
        font-style: italic;
        color: #a2a2a2;
        position: absolute;
        top: calc((100% - .5rem) / 2);
        left: 3.6rem;
        transition: top .3s ease, left .3s ease, font-size .3s ease;
        -o-transition: top .3s ease, left .3s ease, font-size .3s ease;
        -moz-transition: top .3s ease, left .3s ease, font-size .3s ease;
        -webkit-transition: top .3s ease, left .3s ease, font-size .3s ease;

        &, small {
            line-height: .5;
        }
    }
    
    input {
        &:focus, &:not(:placeholder-shown) {
            & ~ span {
                font-size: 1rem;
                top: -1rem;
                left: 2rem;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

@media only screen and (max-width: 600px) {
    input,
    .type-search-list-container {
        font-size: 12px;
    }
    

    #search-result-container {
        max-height: 82vh;
    }

    .search-tool-label {
        font-size: 80%;
    }

    label {
        span {
            left: 3.2rem;
        }
    }
}
</style>

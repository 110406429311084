<template>
    <div 
        :class="[
            'dot-flashing',
            big && 'big'
        ]"
    ></div>
</template>

<script>
export default {
    name: 'DotsFlashingLoader',
    props: {
        big: Boolean
    }
}
</script>

<style scoped lang="scss">
.dot-flashing {
    position: relative;
    background-color: #707070;
    color: #707070;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;

    &.big {
        width: 20px;
        height: 20px;
        border-radius: 10px;
    }
}

.dot-flashing {
    &,
    &::before,
    &::after {
        width: 10px;
        height: 10px;
        border-radius: 5px;
    }
    
    &::before {
        left: -15px;
    }
    
    &::after {
        left: 15px;
    }

    &.big{
        &,
        &::before,
        &::after {
            width: 20px;
            height: 20px;
            border-radius: 10px;
        }
        
        &::before {
            left: -30px;
        }
        
        &::after {
            left: 30px;
        }
    }
}

.dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    background-color: #707070;
    color: #707070;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    background-color: #707070;
    color: #707070;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}
</style>
import axios from "axios";
import store from '../store';
import router from '../router';

var request = (async (path) => {
  let request = path.includes('?') ? path+'&':path+'?';

  return await axios.get(`${process.env.VUE_APP_ROOT_API}${request}key=${store.getters.key}`)
  .then(response => {
    return {
      status: true,
      data: response.data,
      code: response.status
    }
  })
  .catch(error => {
    // eslint-disable-next-line
    console.debug('Request error', error.response, new Date().getTime());
    return {
      status: false,
      code: error.response ? error.response.status:408
    }
  });
});

var apiRequest = (async (path) => {
  if (path) {
    const response = await request(path);

    if (response.status) {
      return response.data;
    } else if (response.code.toString().split('')[0] === '5') {
      setTimeout( async () => {
        const responseReTry = await request(path);
        
        if (responseReTry.status) {
          return response.data;
        } else {
          store.commit('alert', {
            type: 'error',
            message: 'Your request cannot be processed. Please refresh your browser.'
          });

          if (router.currentRoute.value.name !== 'Home') {
            router.go(-1);
          }
        }
      }, 2000);
    } else {
      store.commit('alert', {
        type: 'error',
        message: 'Request failed. Please refresh the page.'
      });

      if (router.currentRoute.value.name !== 'Home') {
        router.go(-1);
      }
    }
  } else {
    // eslint-disable-next-line
    console.error('Request needs a valid path');
  }
});

export default apiRequest;

<template>
  <Container :center="centerHome" show-bg-img>
    <section class="col-12 py-3 text-center">
      <div v-if="hasClientLogo" class="col-12 text my-5">
        <img :src="logo" alt="" class="client-logo">
      </div>

      <div class="col-10 mx-auto mt-4 mb-3" style="max-width: 520px">
        <SearchTool/>
      </div>

      <div
        :class="[
          'py-2',
          (vehicleURL === '#' || productURL === '#') && 'cards-disabled'
        ]"
      >
        <Card :link="vehicleURL">
          <div class="mt-4">
            <small class="text-muted">SHOP BY</small>
            <h5 class="fw-bold text-brand-text">VEHICLE</h5>
          </div>
          <img v-if="hasClientVehicleImage"  :src="vehicleImage" alt="Vehicles" class="mb-3 user-select-none">
          <img v-else src="https://vvs.autosyncstudio.com/static/vehicles.png" alt="Vehicles" class="mb-3 user-select-none">
        </Card>
        <Card :link="productURL">
          <div class="mt-4">
            <small class="text-muted">SHOP BY</small>
            <h5 class="fw-bold text-brand-text">PRODUCT</h5>
          </div>
          <img v-if="hasClientProductImage"  :src="productImage" alt="Products" class="mb-3 user-select-none">
          <img v-else  src="https://vvs.autosyncstudio.com/static/wheels.png" alt="Products" class="mb-3 user-select-none">
        </Card>
      </div>
    </section>
  </Container>
</template>

<script>
import Container from "@/components/Container.vue";
import SearchTool from "@/components/home/SearchTool.vue";
import Card from "@/components/shared/Card.vue";
import apiRequest from "@/utils/apiRequest";

export default {
  name: "Home",
  components: {
    Container,
    SearchTool,
    Card
  },
  data: function () {
    return {
      vehicleURL: '#',
      productURL: '#'
    }
  },
  computed: {
    hasClientLogo: function () {
      if (this.$store.getters.clientSettings.logo) {
        return true;
      }
      return false;
    },
    hasClientVehicleImage: function () {
      if (this.$store.getters.clientSettings.vehicleImage) {
        return true;
      }
      return false;
    },
    hasClientProductImage: function () {
      if (this.$store.getters.clientSettings.productImage) {
        return true;
      }
      return false;
    },
    logo: function () {
      if (this.$store.getters.clientSettings.logo) {
        return this.$store.getters.clientSettings.logo;
      }
      return '';
    },
    vehicleImage: function () {
      if (this.$store.getters.clientSettings.vehicleImage) {
        return this.$store.getters.clientSettings.vehicleImage;
      }
      return '';
    },
    productImage: function () {
      if (this.$store.getters.clientSettings.productImage) {
        return this.$store.getters.clientSettings.productImage;
      }
      return '';
    },
    centerHome: function () {
      return this.$store.getters.clientSettings.home.center;
    }
  },
  mounted: async function () {
    let makes = await apiRequest('/vehicles/makes?f-types=Truck,Van,Car,SUV&i-logos&p-size=500');
    this.$store.commit('vehiclesMakes', {baseUrl: makes.LogoUrlBase || null, makes: makes.Makes || null});
    
    let wheels = await apiRequest('/wheels/brands?i-logos&p-size=500');
    this.$store.commit('wheelsBrands', {baseUrl: wheels.BrandLogosUrlBase || null, brands: wheels.Brands || null});

    this.vehicleURL = this.$store.getters.vehiclesMakes.makes.length === 1 ? `/vehicles/make/${this.$store.getters.vehiclesMakes.makes[0].Make}`:'/vehicles';
    this.productURL = this.$store.getters.clientSettings.brands === 1 ? `/builder?wheels_view&wheel_brand=${this.$store.getters.wheelsBrands.brands[0].Brand}`:'/wheels';
  }
};
</script>

<style scoped lang="scss">
img {
  max-height: 180px;
  max-width: 100%;
}

.cards-disabled {
  filter: grayscale(1) opacity(.6803);
}

@media only screen and (max-width: 600px) {
  img.client-logo {
    max-height: 10vh;
  }
}
</style>
